<template>
  <vca-card></vca-card>
</template>
<script>
export default {
  name: "WikiView",
  mounted() {
    window.location.href = process.env.VUE_APP_WIKI_URL;
  },
};
</script>
